









































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { nylasStoredEvent, nylasStoredStartEvent } from '@/integrations/models/nylas';
import { EventTypes } from '@/constants/event-type-constants';
import { VForm } from '@/types/types';
import { StaffRepository } from '@/staff/repositories/staff-repository';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import BaseClose from '@/components/base/BaseClose.vue';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const nylasRepo = new NylasRepository();
const staffRepo = new StaffRepository();
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const featuresState = getModule(FeaturesStore);

@Component({
    components: { BaseClose }
})
export default class NylasEmailAuth extends Mixins(VmodelShowMixin, LocaleMixin, BasicValidationMixin) {
    @Prop({ default: '' }) readonly reauthEmail!: string;
    private nylasUrl = '';
    private email = '';
    private valid = false;
    private loadingKey = 'nylasEmailAuth';
    private noClose = false;
    private popup: Window | null = null;

    @Ref('form') readonly form!: VForm;

    get isReauth() {
        return !!this.reauthEmail;
    }

    get useGoogleButton() {
        return featuresState.isFeatureEnabled(FeatureConstants.NYLAS_GOOGLE_LOGIN);
    }

    @Watch('modelValue')
    opened() {
        if (this.modelValue) {
            this.email = '';
            if (this.form) {
                this.form.reset();
            }
            if (this.reauthEmail) {
                this.email = this.reauthEmail;
            }
            this.nylasUrl = '';
            this.noClose = false;
            this.popup = null;
            window.addEventListener('message', this.handlePopup);
        } else {
            if (this.popup) {
                this.popup.close();
            }
            window.removeEventListener('message', this.handlePopup);
        }
    }

    async close(doRevoke = false) {
        if (doRevoke) {
            loadingState.loadingIncrement(this.loadingKey);
            await nylasRepo.revokeEmailIntegration();
            if (authState.id) {
                const userInfo = await staffRepo.getOne(authState.id);
                authState.storeUserInfo(userInfo);
            }
            loadingState.loadingDecrement(this.loadingKey);
        }
        this.modelValue = false;
    }

    handlePopup(evt: MessageEvent) {
        if (evt.origin !== window.origin) {
            return;
        }
        if (evt.data.event === nylasStoredStartEvent) {
            this.noClose = true;
        }
        if (evt.data.event === nylasStoredEvent) {
            this.$emit(EventTypes.ADDED);
            this.close();
        }
    }

    async proceed() {
        if (!this.isReauth && !this.valid) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        if (!this.isReauth) {
            const valid = await nylasRepo.validateEmailAddress(this.email);
            if (!valid) {
                await this.$swal({
                    icon: 'warning',
                    text: 'This address is already integrated. Please try another email address.'
                });
                loadingState.loadingDecrement(this.loadingKey);
                return;
            }
        } else {
            this.email = this.reauthEmail;
        }
        const url = await nylasRepo.getEmailAuthUrl(this.email);

        this.nylasUrl = url;
        this.popup = window.open(this.nylasUrl, '_blank', 'menubar=no,toolbar=no,width=500,height=600');
        loadingState.loadingDecrement(this.loadingKey);
        const checkClosedTimer = window.setInterval(() => {
            if (this.popup?.closed) {
                window.clearInterval(checkClosedTimer);
                this.close();
            }
        }, 200);
    }
}
